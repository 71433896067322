<template>
  <main class="Orders">
    <TheSubHeader
      icon="package-delivery-alt"
      title="Pedidos"
      page-title-tag="Orders"
      type="filter"
      organizer-gap="1rem"
      organizer-columns="1fr 1fr 1fr 1fr"
    >
      <template #beforeButtons>
        <!-- TODO: Inserir permissão nesse botão assim que a mesma for inserida na plataforma -->
        <BaseButton
          v-if="permissions && permissions.orders && permissions.orders.packagesButton"
          icon="package"
          color="dark"
          label="Pacotes"
          :track="trackSubheaderButtonString('Orders', 'Packages')"
          @click="goTo('/packages')"
        />
        <BaseButton
          v-if="permissions && permissions.orders && permissions.orders.deliveryFailure"
          icon="package-request-fail"
          color="dark"
          label="Insucesso de entrega"
          :track="trackSubheaderButtonString('Orders', 'Delivery Failure')"
          @click="goTo('/orders/deliveryFailure')"
        />
        <BaseButton
          v-if="permissions && permissions.orders && permissions.orders.incomingOrderButton"
          icon="package-request"
          color="dark"
          label="Recebimento de físico"
          :track="trackSubheaderButtonString('Orders', 'Orders Incoming')"
          @click="goTo('/orders/incoming')"
        />
        <BaseButton
          v-if="permissions && permissions.orders && permissions.orders.changeOrderStatusButton"
          icon="export-alt"
          color="dark"
          label="Mudar Status"
          :track="trackSubheaderButtonString('Orders', 'Change Status')"
          @click="goTo('/orders/changeStatus')"
        />
        <div v-if="moreActions.length > 0">
          <BaseTreeSelect
            class="TheSubHeader__ActionButton"
            theme="actionButton"
            placeholder="mais ações"
            :select-options="moreActions"
            @input="goToPage"
          />
        </div>
      </template>
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Intervalo entre datas (max. 15 dias)"
          :max-range="15"
        />
        <!-- Stores -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.storeLabel"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="filterStores"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filters.storeStatus"
          name="Status"
          label="Status"
          placeholder="Selecione"
          multiple
          :read-only="disabledFields.storeStatus"
          :select-options="status"
          @input="updateTags()"
        />
        <!-- Transporters -->
        <BaseTreeSelect
          ref="transporter"
          v-model="filters.transporter"
          name="Transportadora"
          label="Transportadora"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'transporters/fetchTransportersAsync'"
          :fetch-params="1"
          @input="updateTags()"
        />
        <!-- Client -->
        <InputGroup
          v-model="filters.client"
          name="Cliente"
          label="Cliente"
          placeholder="Nome do cliente"
        />
        <!-- Driver -->
        <BaseTreeSelect
          v-model="filters.driver"
          name="Motorista"
          label="Motorista"
          placeholder="Selecione"
          multiple
          suggestions="searchDrivers"
          @input="updateTags()"
        />
        <!-- Orders -->
        <BaseTreeSelect
          ref="query"
          v-model="filters.query"
          name="Pesquisar"
          label="Pesquisar"
          placeholder="Pesquisar"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          :enable-bipping="true"
          :tooltip="tooltip"
          :max-items="5000"
          @configBiping="configBiping"
          @bipping="itsBipping = true, onInputBarcodeScanned($event)"
          @not-bipping="onCodeDefault"
          @select="callbackInputBarcodeScanned"
          @deselect="callbackInputBarcodeScanned"
          @clear="callbackInputBarcodeScanned"
        />
        <!-- Filter -->
        <BaseButton
          label="Filtrar"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          @click="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="Orders__content">
      <div v-if="firstSearch" class="Orders__table">
        <DataTable
          :header="orders.data.headers"
          :table="orders.data.content"
          :disabled="disabledItem"
          is-sticky
          @see="seeOrder($event), trackTableAction('Orders', 'Details', $event)"
          @track="trackOrder($event), trackTableAction('Orders', 'Tracking', $event)"
          @payment="confirmCreateNewOrderPaymentLink($event), trackTableAction('Orders', 'Payment', $event)"
          @downloadCollect="downloadCollectCodes($event), trackTableAction('Orders', 'Download Collect Codes', $event)"
          @downloadReport="downloadPackagesReport($event), trackTableAction('Orders', 'Download Packages Report', $event)"
          @downloadLabel="downloadPackageLabel($event), trackTableAction('Orders', 'Download Order Labels', $event)"
          @disableLocationLock="confirmSubmitOrderLocationLock($event, false), trackTableAction('Orders', 'Disable Geolocation Lock', $event)"
          @enableLocationLock="confirmSubmitOrderLocationLock($event, true), trackTableAction('Orders', 'Enable Geolocation Lock', $event)"
          @pdf="handlePrintPdf($event), trackTableAction('Orders', 'Print PDF', $event)"
          @zpl="handlePrintZpl($event), trackTableAction('Orders', 'Print Zebra', $event)"
          @nf="downloadNfe($event), trackTableAction('Orders', 'Download NFe', $event)"
        />
        <Pagination
          @page="search()"
          @paginate="search()"
        />
      </div>
      <div v-else class="Orders__firstSearch">
        <p>Utilize o filtro para exibir os pedidos.</p>
      </div>
    </div>
    <Dialog
      v-if="showConfirmDialog"
      :warning="confirmDialogMsg"
      label-cancel="Não"
      label-confirm="Sim"
      :dialog-action="confirmDialogAction"
      @confirm="confirmDialog"
      @cancel="cancelDialog"
    />

    <BarcodeScannerSettings
      ref="barcodeScannerSettings"
      v-bind="currentProperties"
      :stores="storesToBarcodeSettings"
      @save="handlerSaveBarcodeScannerSetting"
      @restore="handlerRestoreBarcodeScannerSetting"
      @delete="handlerDeleteBarcodeScannerSetting"
    />
  </main>
</template>

<script>
import { TheSubHeader } from '@/components/organisms'
import { BaseTreeSelect, BaseButton, BaseDatePicker } from '@/components/atoms'
import { Pagination, Dialog, DataTable, FiltersTag, BarcodeScannerSettings, InputGroup } from '@/components/molecules'
import { drivers } from '@/api'
import { orders } from '@/api'
import { packages } from '@/api'
import { mapActions, mapState } from 'vuex'
import zebraPrint from '@/mixins/zebraPrint'
import { BarCodeScanner } from '@/plugins/barCodeScanner/index'
import barCodeScannerMixin from '@/mixins/barCodeScanner'

export default {
  name: 'Orders',
  components: {
    TheSubHeader,
    BaseDatePicker,
    DataTable,
    Pagination,
    BaseTreeSelect,
    BaseButton,
    Dialog,
    FiltersTag,
    BarcodeScannerSettings,
    InputGroup
  },
  mixins: [zebraPrint, barCodeScannerMixin],
  data() {
    return {
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        storeLabel: [],
        storeStatus: [],
        query: [],
        client: null,
        driver: [],
        transporter: []
      },

      confirmDialogMsg: '',
      confirmDialogAction: '',
      allTags: [],
      disabled: null,
      disabledItem: null,
      idOrder: 0,
      showConfirmDialog: false,
      exportStatus: {},
      firstSearch: false,
      orderLocationLock: false,
    }
  },
  computed: {
    ...mapState({
      orders: state => state.orders.orders,
      status: state => state.orders.status,
      permissions: state => state.auth.permissions,
      queryString: state => state.queryString.queryString,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      zebraPrinter: state => state.printer.zebraPrinter,
      printerConnected: state => state.printer.printerConnected,
    }),

    //Periodo
    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    dateRangeInit() {
      const days = 15
      const dateOffset = (24*60*60*1000) * days
      const calculatedDate = new Date()
      calculatedDate.setTime(calculatedDate.getTime() - dateOffset)
      return calculatedDate.getTime()
    },

    dateRangeEnd() {
      const calculatedDate = new Date()
      const dateNow = new Date(calculatedDate)
      return dateNow.getTime()
    },

    todayParsed() {
      let today = new Date()
      return Date.parse(today)
    },

    //Disable
    disabledFields() {
      return {
        storeStatus: this.filters.storeLabel && this.filters.storeLabel.length !== 1
      }
    },

    appendHeaders() {
      const appendHeader = {...this.orders.data.headers, ['store.actions']: {...this.orders.data.headers['store.actions'], label: 'Ações', action: true}}
      return appendHeader
    },

    driverId(){
      return this.$route.query.driverId
    },

    searchDisabled() {
      return this.filters.storeLabel.length === 0 || this.filters.queryType === ''
    },

    moreActions() {
      let addOrder = this.permissions.orders.addNewButton
      let exportOrder = this.permissions.orders.exportButton
      let importOrder = this.permissions.orders.importButton

      return [
        addOrder ? { id: 0, name: 'cadastrar pedido', value: '/orders/add' } : [],
        exportOrder ? { id: 1, name: 'exportar', value: '/orders/export' } : [],
        importOrder ? { id: 2, name: 'importar', value: '/orders/import' } : [],
      ]
    }

  },
  mounted() {
    this.setInitialDate()
    this.loadDriverInfo()
    if(this.$route.params.order) this.setInitialOrderNumber()
  },
  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      statusMassive: 'orders/statusMassive',
      createNewOrderPaymentLink: 'orders/createNewOrderPaymentLink',
      submitOrderLocationLock: 'orders/submitOrderLocationLock',
      getStatus: 'orders/getStatus',
      setParameters: 'queryString/setParameters',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      setLoading: 'loading/setLoading',
      setError: 'error/setError',
    }),

    //[INFO] - Barcode Settings Methods are insade mixin (barCodeScannerMixin)
    ...BarCodeScanner({
      onCodeDefault: 'onCodeDefault',
      onCodeSliced: 'onCodeSliced',
      onCodeBipping: 'onCodeBipping',
      callbackBarcodeScanned: 'callbackBarcodeScanned',
      callbackInputBarcodeScanned: 'callbackInputBarcodeScanned',
    }),

    //Filters
    filterStores(data){
      this.storeUpdate()
      this.filterBarcodeSettings(data, this.callbackBarcodeScanned)
    },

    setInitialOrderNumber() {
      const order = this.$route.params.order
      this.filters.query = [{ id: order, label: order, name: order } ]
      this.updateTags()
      this.resetSearch()
    },

    setInitialDate() {
      this.$set(this.filters.dateRange, 'init', this.dateRangeInit)
      this.$set(this.filters.dateRange, 'end', this.dateRangeEnd)
    },

    async loadDriverInfo() {
      if (this.driverId) {
        this.setLoading(true)
        await drivers.fetchDriver({ id: this.driverId }, (res) => {
          this.filters.driver.push({
            id: this.driverId,
            name: res.name
          })
        }, (e) => e)
        this.setLoading(false)
        this.updateTags()
        this.search()
      }
    },

    seeOrder(data) {
      this.openInNewTab(`/orders/${data.id}`)
    },

    confirmCreateNewOrderPaymentLink(data) {
      this.idOrder = data.id
      this.confirmDialogMsg = 'Tem certeza que deseja gerar um novo link de pagamento para esse pedido?'
      this.confirmDialogAction = 'createNewOrderPaymentLink'
      this.showConfirmDialog = true
    },

    confirmSubmitOrderLocationLock(data, check_location) {
      this.orderLocationLock = check_location
      this.idOrder = data.id
      this.confirmDialogMsg = `Tem certeza que deseja ${check_location ? 'habilitar' : 'desabilitar'} a trava de geolocalização para esse pedido?`
      this.confirmDialogAction = 'submitOrderLocationLock'
      this.showConfirmDialog = true
    },

    confirmDialog() {
      if (this.confirmDialogAction === 'createNewOrderPaymentLink') {
        this.createNewOrderPaymentLink({
          id: this.idOrder
        })
      }
      if (this.confirmDialogAction === 'submitOrderLocationLock') {
        this.submitOrderLocationLock({
          id: this.idOrder,
          check_location: this.orderLocationLock
        })
      }
      this.showConfirmDialog = false
    },

    cancelDialog(){
      this.showConfirmDialog = false
    },

    trackOrder(data){
      if (!data.tracking_code) return

      const url = `/tracking/${data.tracking_code}`
      this.openInNewTab(url)
    },

    storeUpdate(){
      this.filters.storeStatus = []
      if (this.filters.storeLabel && this.filters.storeLabel.length === 1) {
        this.getStatus({id: this.filters.storeLabel[0].id})
      }
    },

    updateTags(){
      this.allTags = [
        ...this.filters.storeLabel,
        ...this.filters.query,
        ...this.filters.storeStatus,
        ...this.filters.driver,
        ...this.filters.transporter
      ]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.storeLabel = this.filters.storeLabel.filter((tag) => tag.name !== name)
      this.filters.query = this.filters.query.filter((tag) => tag.name !== name)
      this.filters.storeStatus = this.filters.storeStatus.filter((tag) => tag.name !== name)
      this.filters.driver = this.filters.driver.filter((tag) => tag.name !== name)
      this.filters.transporter = this.filters.transporter.filter((tag) => tag.name !== name)
      this.updateStoresBipingSettings()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.storeLabel = []
      this.filters.query = []
      this.filters.storeStatus = []
      this.filters.client = null
      this.filters.driver = []
      this.filters.transporter = []
      this.updateStoresBipingSettings()
    },

    resetSearch() {
      this.clearPagination(this.paginate).then(() => this.search())
    },

    search(){

      this.$nextTick(async () => {
        this.firstSearch = true
        const params = {
          obj: {
            date_start: this.filters.dateRange.init,
            date_end: this.filters.dateRange.end,
            store_id: this.$refs.lojista.checkSelectAll ? [] : this.filters.storeLabel,
            store_status_id: this.filters.storeStatus,
            query: this.filters.query,
            transporter_id: this.filters.transporter,
            orderBy: 'orders.id'
          },
          driver_id: '',
          client_id: this.filters.client,
          paginate: this.paginate,
          page: this.page
        }

        if (this.filters.driver.length !== 0 ) {
          params.driver_id = this.filters.driver[0].id
          delete params.obj.date_start
          delete params.obj.date_end
        } else {
          window.history.pushState({}, document.title, window.location.pathname)
        }

        this.setParameters(this.filters)
        this.fetchOrders(params)
          .then(() => this.setPagination(this.orders))
      })
    },

    async downloadCollectCodes(e) {
      this.setLoading(true)
      await orders.downloadCollectCodes(e.id, (r) => r, (e) => e, () => this.setLoading(false))
    },

    async downloadPackagesReport(e) {
      this.setLoading(true)
      const obj = {
        order_id: [{ id: e.id }],
      }

      await packages.downloadOrderWithPackageReport({obj}, (r) => r, (e) => e, () => this.setLoading(false))
    },

    async downloadPackageLabel(e) {
      this.setLoading(true)
      await packages.downloadPackagesLabel(e.id, (r) => r, (e) => e, () => this.setLoading(false))
    },

    async downloadNfe(e) {
      this.setLoading(true)
      await orders.downloadNfe(e.order_number, (r) => r, (e) => e, () => this.setLoading(false))
    },

    async handlePrintPdf(data) {
      const id = data && data.id ? data.id : null
      if (!id) {
        return
      }

      this.setLoading(true)
      await packages.downloadPackagesLabel(id, (r) => r, (e) => e, () => this.setLoading(false))

    },
    async handlePrintZpl(data) {
      let time = 0

      if (!this.zebraPrinter || !this.printerConnected) {
        time = 2000
        await this.initZebra()
      }

      setTimeout(() => {
        if (this.zebraPrinter && this.printerConnected) {
          this.getZPLCode(data)
        } else {
          this.setError('A impressora não está conectada ou não está respondendo. Conecte a impressora ou peça ajuda para alguém do time de Suporte.')
        }
      }, time)

    },

    async getZPLCode(data) {
      if (!data?.id) {
        return
      }

      await orders.getZPLCode(data.id, (res) => {
        res.forEach(el => {
          if (this.printerReady) this.print(el)
        })
      }, (e) => e, () => this.setLoading(false))

    },

    goTo(to) {
      this.$router.push(to)
    },

    goToPage(data) {
      this.goTo(data?.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.TheSubHeader__ActionButton {
  width: 150px;
  min-width: initial;
  max-width: initial;

  @media #{$mobile-view} {
    width: 100%;
  }
}
.Orders {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__table {
    grid-area: main;
    margin-bottom: 50px;

    /deep/ .cubage {
      min-width: 160px;
    }

    /deep/ [class*="addresses.destiny_zip"] {
      min-width: 65px;
    }
  }

  &__firstSearch {
    grid-area: main;
    margin-bottom: 50px;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin: 0;
      padding: 0;
      text-align: center;
      background: $gradient-primary;
    }
  }

  &__Filter{
    &--able{
      pointer-events: auto;
      opacity: 1;
    }
    &--disabled{
      pointer-events: none;
      opacity: .3;
    }
  }
}
</style>
<style lang="scss">
.TheSubHeader {
  .Organizer__filter {
    align-items: flex-end;
  }
}
</style>